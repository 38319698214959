import { render, staticRenderFns } from "./TextWithTooltip.vue?vue&type=template&id=6bd00495&scoped=true"
import script from "./TextWithTooltip.vue?vue&type=script&lang=js"
export * from "./TextWithTooltip.vue?vue&type=script&lang=js"
import style0 from "./TextWithTooltip.vue?vue&type=style&index=0&id=6bd00495&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd00495",
  null
  
)

export default component.exports