<template>
  <div>
    <content-block>
      <content-title slot="title" justify="space-between">
        <span>企业用户详情</span>
      </content-title>
      <el-card>
        <el-descriptions size="medium" :colon="false">
          <el-descriptions-item :span="3">
            <div class="info">
              <el-avatar :src="tenantDetail.logo" size="large"></el-avatar>
              <div class="name_and_status">
                <div class="name">{{ tenantDetail.name | commonFormat }}</div>
                <div :class="{
                  unauth: tenantDetail.authenticationStatus === '1',
                  authing: tenantDetail.authenticationStatus === '2',
                  authed: tenantDetail.authenticationStatus === '3',
                }">
                  {{ authStatusEnum[tenantDetail.authenticationStatus] || '--' }}
                </div>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码：">
            {{ tenantDetail.certCode | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="企业规模：">
            {{ tenantDetail.scale | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="经营类型：">
            {{ tenantDetail.businessTypeStr | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="经营地区：" :labelStyle="{ 'flex-shrink': 0, width: '6em' }">
            <el-tooltip class="item" effect="dark" :content="tenantDetail.businessAreaStr" placement="right-start">
              <div class="long-text">{{ tenantDetail.businessAreaStr }}</div>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="企业介绍：" :span="3" :labelStyle="{ display: 'inline-block', width: '6em' }" :contentStyle="{flex: 1}">
            {{ tenantDetail.remark | commonFormat }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="注册认证" name="registerTab">
          <template v-if="tenantDetail && tenantDetail.registerAuthenticationRespDTO">
            <el-card>
              <el-descriptions size="medium" :colon="false">
                <el-descriptions-item :span="3">
                  {{ tenantDetail.registerAuthenticationRespDTO.createTime | formatDateTime }}
                  平台注册
                </el-descriptions-item>
                <el-descriptions-item label="创建人：">
                  {{ tenantDetail.registerAuthenticationRespDTO.createUserName | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="创建人手机号：">
                  {{ tenantDetail.registerAuthenticationRespDTO.applicantPhoneNumber | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="创建应用端：">
                  {{ registerSourceEnum[tenantDetail.registerAuthenticationRespDTO.registerSource] | commonFormat }}
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
            <el-card class="gap-top-10">
              <el-descriptions size="medium" :colon="false">
                <el-descriptions-item :span="3">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationTime | formatDateTime }}
                  {{
                    AUTH_SUBMITTYPE_ENUM[tenantDetail.registerAuthenticationRespDTO.authenticationSubmitType]
                    | commonFormat
                  }}
                </el-descriptions-item>
                <el-descriptions-item label="管理员姓名：">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationAdminName | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="管理员手机号：">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationAdminPhone | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="管理员证件号：">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationAdminIdcard | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="提交人姓名：">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationSubmitName | commonFormat }}
                </el-descriptions-item>
                <el-descriptions-item label="提交时间：" :span="2">
                  {{ tenantDetail.registerAuthenticationRespDTO.authenticationTime | formatDateTime }}
                </el-descriptions-item>
                <el-descriptions-item label="认证公函：">
                  <template v-if="tenantDetail.registerAuthenticationRespDTO && tenantDetail.registerAuthenticationRespDTO.certificationOfficials.length">
                    <div v-for="cert in tenantDetail.registerAuthenticationRespDTO.certificationOfficials" :key="cert.certificationOfficialUrl">
                        <a :href="cert.certificationOfficialUrl" target="_blank">{{ getName(cert.certificationOfficialName) }}</a>
                    </div>
                  </template>
                  <span v-else>--</span>
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
          </template>
          <el-empty v-else description="暂无数据"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="行业能力" name="industryTab">
          <content-block>
            <content-title slot="title" justify="space-between">
              <span>维修服务能力
                <span v-if="tenantDetail.auditStatus" class="approval-status green"
                :class="{
                  'green': tenantDetail.auditStatus === 'PASS',
                  'orange': tenantDetail.auditStatus === 'WAIT',
                  'red': tenantDetail.auditStatus === 'REJECT'
                }">
                审核{{ tenantDetail.auditStatus === 'PASS' ? '通过' : tenantDetail.auditStatus === 'REJECT' ? '失败' : '中' }}</span>
              </span>
            </content-title>
            <el-card>
              服务地区
              <div class="tag-wrapper">
                <el-tag v-if="tenantDetail.itemList.filter(v => v.type === 1).length === 34">全国</el-tag>
                <el-tag v-else v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 1)" type="success"
                  :key="'area-'+i">
                  {{ item.capacityValue }}
                </el-tag>
              </div>
            </el-card>
            <el-card class="gap-top-10">
              服务设备品牌
              <div class="tag-wrapper">
                <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 2)" type="success"
                  :key="'brand-'+i">
                  {{ item.capacityValue }}
                </el-tag>
              </div>
            </el-card>
            <el-card class="gap-top-10">
              服务设备分类
              <div class="tag-wrapper">
                <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 4)" type="success"
                  :key="'device-'+i">
                  {{ item.capacityValue }}
                </el-tag>
              </div>
            </el-card>
            <el-card class="gap-top-10">
              能力标签
              <div class="tag-wrapper">
                <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 3)" type="success"
                  :key="'tags-'+i">
                  {{ item.capacityValue }}
                </el-tag>
              </div>
            </el-card>
            <el-card class="gap-top-10">
              证明材料
              <div class="tag-wrapper">
                <template v-if="tenantDetail.credentialMaterialList && tenantDetail.credentialMaterialList.length">
                  <div v-for="(cert, i) in tenantDetail.credentialMaterialList" :key="'cert-'+i" style="display: inline-block; margin-right: 20px;">
                    <a :href="cert.url" target="_blank">{{ cert.name }}</a>
                  </div>
                </template>
              </div>
            </el-card>
          </content-block>
        </el-tab-pane>
        <el-tab-pane label="成员信息" name="memberTab">
          <table-with-pagination :tableData="tenantDetail.employeeRespDTO" class="content_block"
            :columnList="memberColumnList" />
        </el-tab-pane>
        <el-tab-pane label="企业证件" name="companyTab">
          <table-with-pagination :tableData="tenantDetail.tenantCertificateRespDTO" class="content_block"
            :columnList="certiColumnList" />
        </el-tab-pane>
        <el-tab-pane label="开通服务" name="servceTab">
          <table-with-pagination :tableData="tenantDetail.tenantServiceInfoList" class="content_block"
            :columnList="serviceColumnList" />
        </el-tab-pane>
      </el-tabs>
    </content-block>
  </div>
</template>
<script>
import { getTenantDetail } from '@/api/tenant';
import * as dayjs from 'dayjs';
import authStatusEnum from '@/model/xzpt/auth';
import registerSourceEnum from '@/model/xzpt/registerSource';
import defaultAvatar from '@/assets/imgs/avatar.svg';
import { dateFormat } from '@/utils/dateFormat';
import TableWithPagination from '@/components/common/TableWithPagination';
import BusinessTypeEnum from '@/model/xzpt/businessTypes';
import TextWithTooltip from '@/components/common/TextWithTooltip.vue';
import { industryCapacityDetail } from '@/api/industryCapability';

const AUTH_SUBMITTYPE_ENUM = {
  1: '小紫认证',
  2: '电子签章',
};

export default {
  name: 'TENANT_DETAIL',
  props: {
    id: {
      type: String,
    },
  },
  components: {
    TableWithPagination,
  },
  data () {
    return {
      tenantDetail: {
        tenantCertificateRespDTO: [],
        auditStatus: null,
        itemList: [],
        credentialMaterialList: [],
      },
      activeName: 'registerTab',
      authStatusEnum,
      registerSourceEnum,
      AUTH_SUBMITTYPE_ENUM,
      memberColumnList: [
        {
          tooltip: true,
          label: '姓名',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '身份证号码',
          minWidth: '150px',
          prop: 'idcard',
        },
        {
          tooltip: true,
          label: '平台账号',
          minWidth: '100px',
          prop: 'account',
        },
        {
          tooltip: true,
          label: '加入时间',
          minWidth: '150px',
          prop: 'createTime',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '是否管理员',
          minWidth: '150px',
          prop: 'isAdmin',
          format: v => {
            return v ? '是' : '否';
          },
        },
      ],
      serviceColumnList: [
        {
          tooltip: true,
          label: '服务名称',
          minWidth: '150px',
          prop: 'name',
          render: (h, { row }) => {
            return h('span', row.productName || row.name);
          },
        },
        {
          tooltip: true,
          label: '开通时间',
          minWidth: '150px',
          prop: 'openDate',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD') : '--';
          },
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '100px',
          prop: 'expiryDate',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD') : '--';
          },
        },
        {
          tooltip: true,
          label: '开通方式',
          minWidth: '150px',
          prop: 'openWayDesc',
        },
        {
          tooltip: true,
          label: '当前服务状态',
          minWidth: '150px',
          prop: 'statusDesc',
        },
        {
          tooltip: true,
          label: '业务员序列号',
          minWidth: '150px',
          prop: 'salespersonSerialNumber',
        },
      ],
      certiColumnList: [
        {
          tooltip: true,
          label: '证件名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '证件号码',
          minWidth: '150px',
          prop: 'number',
        },
        {
          tooltip: true,
          label: '发证日期/授权日期',
          minWidth: '100px',
          prop: 'issueDate',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD') : '--';
          },
        },
        {
          tooltip: true,
          label: '到期日期',
          minWidth: '150px',
          prop: 'expirationDate',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD') : '--';
          },
        },
        {
          tooltip: true,
          label: '附件',
          minWidth: '150px',
          prop: 'status',
          render: (h, { row }) => {
            let actions = [];

            if (row.url.length) {
              row.url.forEach(url => {
                const name = this.getName(url);

                actions.push(
                  <TextWithTooltip content={url}>
                    <span style={{cursor: 'pointer'}} >
                      <a href={url} target='_blank'>{name}</a>
                    </span>
                  </TextWithTooltip>,
                );
              });

              return <div>{...actions}</div>;
            }
          },
        },
      ],
    };
  },
  created () {
    this.id
      && getTenantDetail({ id: this.id, findActivateServiceFlag: true }).then(res => {
        console.log(res);
        industryCapacityDetail({ tenantId: this.id }).then(resp => {
          this.tenantDetail = {...this.tenantDetail, ...res.body, ...resp.body};
          this.tenantDetail.logo = this.tenantDetail.logo || defaultAvatar;
          this.tenantDetail.employeeRespDTO = this.tenantDetail.employeeRespDTO || [];
          this.tenantDetail.tenantServiceInfoList = this.tenantDetail.tenantServiceInfoList || [];
          this.tenantDetail.tenantCertificateRespDTO = this.tenantDetail.tenantCertificateRespDTO || [];

          this.tenantDetail.businessAreaStr = (res.body.businessAreaList || []).map(item => `${item.provinceName}${item.cityName}`).toString() || '--';

          const tenantCertificateRespDTO = {};

          this.tenantDetail.tenantCertificateRespDTO.forEach(v => {
            if (v.name in tenantCertificateRespDTO) {
              tenantCertificateRespDTO[v.name].url.push(v.url);
            } else {
              tenantCertificateRespDTO[v.name] = {
                ...v,
                url: [v.url],
              };
            }
          });

          let keys = Object.keys(tenantCertificateRespDTO);

          this.tenantDetail.tenantCertificateRespDTO = keys.map(k => tenantCertificateRespDTO[k]);

          if (this.tenantDetail.businessType) {
            const valArr = this.tenantDetail.businessType.split(',');
            this.tenantDetail.businessTypeStr = valArr.map(v => BusinessTypeEnum[v]).join(',');
          }

          console.log(this.tenantDetail);
        });
      });
  },
  filters: {
    formatDateTime: val => {
      return val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
    commonFormat: val => {
      return val || '--';
    },
  },
  methods: {
    getName (url) {
      const lastIndex = url.lastIndexOf('/');
      let name = '/';

      if (lastIndex !== -1) {
        name = url.substr(lastIndex + 1);
      }
      return name;
    },
  },
};
</script>

<style scoped lang="scss">
.el-tag {
  margin-right: 10px;
}
.long-text {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gap-top-10 {
  margin-top: 10px;
}

.tag-wrapper {
  padding: 5px 0;
  line-height: 30px;
}

/deep/ .content_title {
  .approval-status {
    margin-left: 10px;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 13px;
    &.green {
      background: #E5FEEB;
      color: #00aa4a;
    }
    &.red {
      background: #FF5959;
      color: #ffffff;
    }
    &.orange {
      background: #FAB007;
      color: #ffffff;
    }
  }
}

/deep/ .el-descriptions-item__content {
  .info {
    display: flex;

    .name_and_status {
      margin-left: 6px;
      font-size: 12px;
      font-weight: bold;

      .authed {
        color: #1A66FF;
      }

      .unauth {
        color: #B0B4BD;
      }

      .authing {
        color: #FF6619;
      }
    }

    .el-avatar>img {
      width: 100%;
    }
  }

  .el-image {
      width: 100px;
      height: 100px;
      margin: 0 10px 10px 0;
  }
}
</style>
