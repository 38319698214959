import Enum from '@/utils/Enum';

const businessTypes = [
  { name: '厂商', value: 1 },
  { name: '服务商', value: 2 },
  { name: '经销商', value: 3 },
  { name: '其它', value: 4 },
];

export default new Enum(businessTypes);
export { businessTypes as BusinessTypes };
