import Enum from '@/utils/Enum';

const registerSourceList = [{
  value: 1,
  name: '平台小程序',
},
{
  value: 2,
  name: '平台PC',
}];

export default new Enum(registerSourceList);
export { registerSourceList as RegisterSourceList };
