<template>
  <el-tooltip :content="content" :disabled="isTooltipDisabled" placement="top-start">
    <div class="wrapper" @mouseenter="handleMouseEnter" @mouseleave="isTooltipDisabled = true">
      <slot></slot>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'TextWithTooltip',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isTooltipDisabled: true,
    };
  },
  methods: {
    handleMouseEnter (e) {
      let parentWidth = e.target.offsetWidth;
      let contentWidth = e.target.childNodes[0].offsetWidth;

      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isTooltipDisabled = false;
      } else {
        this.isTooltipDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin cell_overflow($w: auto) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $w;
}

.wrapper {
  @include cell_overflow();
}
</style>
