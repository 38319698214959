import server from '../request';

export default {
  getTenantList (param) {
    param.orderByProperty = param.orderByProperty || 'lastUpdateTime';
    param.orderByType = param.orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    delete param.orderByType;
    delete param.orderByProperty;
    return server.post('/xiaozi-saas/wireless/tenant/queryPage', param);
  },

  getDetail (id) {
    return server.post('/xiaozi-saas/wireless/tenant/queryById', {id});
  },

  add ({applicantName, applicantPhoneNumber, applicantPosition, certCode, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    return server.post('/xiaozi-saas/wireless/tenant/add', {applicantName, applicantPhoneNumber, applicantPosition, certCode, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
  },

  active ({id, trialPeriod}) {
    return server.post('/xiaozi-saas/wireless/tenant/active', {id, trialPeriod});
  },

  close ({id, closeReason}) {
    return server.post('/xiaozi-saas/wireless/tenant/close', {id, closeReason});
  },

  delete (id) {
    return server.post('/xiaozi-saas/wireless/tenant/delById', {id});
  },

  resetPwd ({id, newPwd, oldPwd }) {
    return server.post('xiaozi-saas/wireless/tenant/reset', {id, newPwd, oldPwd});
  },

  approve ({id, openType, adminAccount, adminPassword, trialPeriod, trialStatus, applicantName, applicantPhoneNumber, applicantPosition, certCode, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    if (trialStatus === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialPass', {id, openType, adminAccount, adminPassword, trialPeriod, applicantName, applicantPhoneNumber, applicantPosition, certCode, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activePass', {id, openType, adminAccount, adminPassword, trialPeriod});
    }
  },

  reject ({id, rejectReason, status}) {
    if (status === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialReject', {id, rejectReason});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activeReject', {id, rejectReason});
    }
  },

  // 功能描述：绑定柯渡采购平台
  bind (id) {
    return server.post('/xiaozi-saas/wireless/tenant/bind', {id});
  },

  getCompanyInfo (keyWord) {
    return server.post('/xiaozi-saas/wireless/tenant/qcc/fuzzysearch', {keyWord, pageNum: 1});
  },

  // 租户账号列表
  tenantAccountList (param) {
    param.orderByProperty = param.orderByProperty || 'lastUpdateTime';
    param.orderByType = param.orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    return server.post('/xiaozi-saas/wireless/tenant/queryTenantEmployeePage', param);
  },
  // 设置员工为租户管理员
  setAccountAsAdmin (accountId) {
    return server.post('/xiaozi-saas/wireless/tenant/changeSuperAdmin', {id: accountId});
  },
  // 租户新增超级管理员
  addAdminAccount (param) {
    return server.post('/xiaozi-saas/wireless/tenant/addSuperAdmin', param);
  },
};

export const getEmployeeList = (data) => {
  return server.post('/xiaozi-saas/wireless/employee/queryPage', data);
};

export const getEmployeeDetail = (data) => {
  return server.post('/xiaozi-saas/wireless/employee/detailV2', data);
};

export const getTenantList = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/queryPage', data);
};

export const getAuthenticationPage = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/authenticationPage', data);
};

export const getTenantDetail = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/queryById', data);
};

export const tenantAudit = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/audit', data);
};
