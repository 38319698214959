import server from '@/request/encryptRequest';

export const industryCapacityPageList = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/industry/capacity/pageList', data);
};

export const industryCapacityDetail = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/industry/capacity/detail', data);
};

export const industryCapacityAudit = (data) => {
  return server.post('/xiaozi-saas/wireless/tenant/industry/capacity/audit', data);
};
