import Enum from '@/utils/Enum';

const authStatusList = [
  { name: '未认证', value: 1 },
  { name: '认证中', value: 2 },
  { name: '已认证', value: 3 },
];

export default new Enum(authStatusList);
export { authStatusList as AuthStatusList };
